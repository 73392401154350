import random from "../services/random"

export default async function bop(element, location) {
  let rotationString = `${25 * (random(location) - 0.5)}deg`
  let heightValue = 4 + 10 * random(location)
  let heightString = `-${heightValue}rem`
  let duration = 180 + heightValue * 5
  let originalZ = element.style.zIndex
  element.style.zIndex = 999

  await element.animate(
    [
      { transform: "translateY(0px) rotate(0deg)" },
      { transform: `translateY(${heightString}) rotate(${rotationString})` },
    ],
    {
      easing: "ease-out",
      duration,
    }
  ).finished

  await element.animate(
    [
      { transform: `translateY(${heightString}) rotate(${rotationString})` },
      { transform: "translateY(0px) rotate(0deg)" },
    ],
    {
      easing: "ease-in",
      duration,
    }
  ).finished

  element.style.zIndex = originalZ
}
