import * as React from "react"
import Page from "../components/page"
import Logo from "../components/logo"
import SlydrBannerAd from "../components/games/slydr/banner-ad"
import { indexContent } from "./index.module.css"

export default function Index() {
  return (
    <Page>
      <div className={indexContent}>
        <SlydrBannerAd />
        <Logo />
      </div>
    </Page>
  )
}
