import { useLocation } from "@gatsbyjs/reach-router"
import * as React from "react"
import Sqog from "../images/sqog/sqog.svg"
import { analyticsEvent } from "../services/analytics"
import random from "../services/random"
import useBop from "../hooks/use-bop"
import {
  logo,
  logoOuterWrapper,
  logoWrapper,
  sqog,
  logoCaption,
} from "./logo.module.css"

const sqogSayings = [
  "hey",
  "sup?",
  "woof!",
  "hey there",
  "howdy",
  "how's it going?",
  "what's a sqog?",
  "bark!",
  "square + dog = sqog",
]

let sqogSaying

export default function Logo() {
  const location = useLocation()
  const triggerBop = useBop(location)

  sqogSaying =
    sqogSaying || sqogSayings[Math.floor(random(location) * sqogSayings.length)]

  const bop = event => {
    triggerBop(event)

    // track bops in analytics
    analyticsEvent("interaction", {
      event_category: "engagement",
      event_label: "easter_egg",
    })
  }

  return (
    <figure className={logo}>
      <div className={logoOuterWrapper}>
        <div
          className={logoWrapper}
          aria-label="click (or press space) to make the sqog jump!"
          onClick={bop}
          onKeyDown={event => {
            if (event.key === " ") {
              bop(event)
            }
          }}
          tabIndex={0}
          role="button"
        >
          <Sqog className={sqog} width="0" height="0" role="img" />
        </div>
      </div>
      <figcaption className={logoCaption}>{sqogSaying}</figcaption>
    </figure>
  )
}
