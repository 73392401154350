import { useEffect, useState } from "react"
import bop from "../animations/bop"

export default function useBop(location) {
  const [bopTarget, setBopTarget] = useState(null)

  async function doBop(target, location) {
    await bop(target, location)

    setBopTarget(null)
  }

  useEffect(() => {
    if (!bopTarget) {
      return
    }

    doBop(bopTarget, location)
  }, [bopTarget, location])

  if (bopTarget) {
    // return a no-op while bopping
    return () => {}
  }

  // else return an event handler which bops
  return ({ currentTarget }) => {
    setBopTarget(currentTarget)
  }
}
