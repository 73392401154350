export default function random(location = { search: "" }) {
  var fixedValue = parseFloat(getParam("random", location))

  return isNaN(fixedValue) ? Math.random() : fixedValue
}

function getParam(name, location) {
  var params = new URLSearchParams(location.search)

  return params.get(name)
}
