import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { slydrBannerAdWrapper } from "./banner-ad.module.styl"
import "./banner-ad.styl"

export default function BannerAd() {
  const {
    banner: { image: slydrBanner },
    bannerSmall: { image: slydrSmallBanner },
  } = useStaticQuery(
    graphql`
      {
        banner: file(relativePath: { eq: "slydr/slydr_banner_1.png" }) {
          image: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        bannerSmall: file(
          relativePath: { eq: "slydr/slydr_banner_small_1.png" }
        ) {
          image: childImageSharp {
            gatsbyImageData(
              width: 460
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )

  return (
    <OutboundLink
      className={slydrBannerAdWrapper}
      href="https://play.google.com/store/apps/details?id=games.sqog.slydr&utm_source=sqoggames_banner_ad&utm_campaign=homepages"
      target="_self"
      rel="noopener"
    >
      <GatsbyImage
        image={withArtDirection(getImage(slydrBanner), [
          {
            image: getImage(slydrSmallBanner),
            media: "(max-width: 460px)",
          },
        ])}
        className="slydrBannerAd"
        loading="eager"
        alt="Slydr sliding puzzle game - click to download"
      />
    </OutboundLink>
  )
}
